/* General FAQ container */
.faqs {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.faq-left {
  width: 30%;
  text-align: left;
}

.faq-left h1 {
  font-size: 36px;
  margin-bottom: 10px;
  color: #41479b;
}

.faq-left h3 {
  font-size: 18px;
  color: #888;
  margin-bottom: 20px;
}

.faq-left button {
  background-color: #2c357e;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.faq-left button:hover {
  background-color: #41479b;
}

/* FAQ Right Section */
.faq-right {
  width: 60%;
}

/* FAQ Item styling */
.expand-container {
  background-color: inherit;
  margin-bottom: 15px;
  padding: 15px 20px;
  border: 1px solid rgba(65, 71, 155, 0.2);
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.expand-container:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Expand Header */
.expand-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  /* color: #41479b; */
}

/* Content to show/hide */
.expand-content {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #41479b;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .faqs {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }

  .faq-left, .faq-right {
    width: 100%;
    text-align: center;
  }

  .faq-left h1 {
    font-size: 28px;
  }

  .faq-left h3 {
    font-size: 16px;
  }

  .faq-left button {
    padding: 8px 16px;
    font-size: 13px;
  }

  .expand-container {
    padding: 10px 15px;
  }

  .expand-header {
    font-size: 15px;
  }

  .expand-content {
    font-size: 14px;
  }
}
