/* feature.css */
.features{
  margin-bottom: 5rem;
}
/* General Container Styles */
.feature-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
 
  
  font-family: 'Arial', sans-serif;
}

/* Section Headers */
.feature-container h1 {
  text-align: center;
  font-size: 2rem;
  
  margin-bottom: 4rem;
  position: relative;
}

.feature-container h1::after {
  content: "";
  width: 80px;
  height: 4px;
  background-color: #ff6347; /* Accent color */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
}

/* Feature Boxes Container */
.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Individual Feature Box */
.feature-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  padding: 20px;
  background: inherit;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
  
  transition: transform 0.3s;
}

.feature-box:hover {
  transform: translateY(-8px);
}

/* Icon Styling */
.feature-box .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: #ff6347; /* Accent color */
  margin-bottom: 10px;
}

/* Text Styling */
.feature-box p {
  font-size: 1.1rem;
  /* color: #555; */
  margin-top: 10px;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .features-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .feature-box {
    width: 100%;
    padding: 35px;
  }
  .feature-box .icon{
    margin: 10px;
    
  }
}
