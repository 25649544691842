.floating-buttons {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 20px;
  right: 20px;
  gap: 10px;
}

.floating-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  color: white;
  width: 60px; /* Increased size for larger button */
  height: 60px; /* Increased size for larger button */
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  font-size: 25px; /* Increase icon size */
}

.floating-button:hover {
  background-color: #128c7e;
}
