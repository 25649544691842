/* Container Styling */
/* 
.background-image {
  
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;
} */

h6 {
  font-family: 'Arial', sans-serif;
}

.text-orange-600 {
  color: #FF7043;
}

.text-red-600 {
  color: #E53935;
}

/* Form Section */
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input,
select,
.DatePicker__input {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 16px;
  /* color:inherit; */
  width: 100%;
}

input::placeholder,
select {
  color: #9e9e9e;
}

input:focus,
select:focus {
  outline: none;
  border-color: #42a5f5;
  box-shadow: 0 0 5px rgba(66, 165, 245, 0.4);
}

/* Error Message Styling */
.error-message {
  color: #FF7043;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.feature-container {
  margin-top: 6rem;
}

.text-orange-700 {
  color: #EF6C00;
}

/* Icon & Date Picker Styling */
.icon,
.DatePicker__input {
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

/* Button Styling */
.BookNowButton {
  background-color: #42a5f5;
  color: white;
  padding: 0.75rem 2rem;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.BookNowButton:hover {
  background-color: #1e88e5;
}

/* Responsive Styling */
@media (min-width: 768px) {
  .background-image {
    display: flex;
    justify-content: space-between;
  }

  .form-section {
    flex: 1;
    padding: 2rem;
  }

  .image-section {
    flex: 1;
    padding: 0;
    border-radius: 0;
  }
}

@media (max-width: 768px) {
  /* Ensure input fields use full width on mobile */
  input,
  select,
  .DatePicker__input {
    width: 100% !important;
  }
}

img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
