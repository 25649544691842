.booking-container {
  padding: 40px;

  text-align: center;
}

h2 {
  font-size: 28px;
  margin-bottom: 50px;
  font-weight: bold;
  /* color: white; */
}

.process-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.process {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* border: 2px solid black; */
  border-radius: 10px;
  width: 180px;
  height: 200px;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  
}
.process:hover{
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background-color: inherit; 
  border-color: #0d7b0d; 
}

.circle {
  width: 80px; /* Ensure perfect circle size */
  height: 80px; /* Ensure perfect circle size */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  margin-bottom: 10px;
  /* Default border color */
}

/* Make sure the icons are not squeezed */
.circle svg {
  font-size: 32px; /* Set the icon size */
}

/* Colors for each circle's border */
.green {
  border: 3px solid #32CD32; /* Green border */
  color: #32CD32;
}

.blue {
  border: 3px solid #1E90FF; /* Blue border */
  color: #1E90FF;
}

.pink {
  border: 3px solid #FF69B4; /* Pink border */
  color: #FF69B4;
}

.yellow {
  border: 3px solid #FFD700; /* Yellow border */
  color: #FFD700;
}

.step-info {
  text-align: center;
}

.step-info p {
  font-size: 14px;
  max-width: 120px;
  color: #666;
  text-align: center;
  margin: 5px 0 0 0;
}

/* Arrow Styles */
.arrow {
  font-size: 24px;
  color: #888;
  margin: 0 10px;
  align-self: center; /* Ensure arrow is centered */
}

/* Responsive Design */
@media (max-width: 768px) {
  .booking-container{
    padding:5px;
   
  }
  .process-row {
    flex-direction: column;
   
  }
  .process{
     width:90%;
   
  }
  .arrow {
    transform: rotate(90deg);
    margin: 20px 0;
  }
}
