@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.1;
    }
    100% {
      transform: scale(1.1);
      opacity: 1;
    }
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-logo {
    width: 100%;
    height: 100%;
    animation: rotate 2s linear infinite, pulse 2s ease-in-out infinite;
  }
  