/* Testimonial Section */
.testimonial-section {
  padding: 4rem 1rem;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  
  margin-bottom: 2rem;
  position: relative;
}

.section-title::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #ff6347;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.testimonial-card {
  
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}



.testimonial-content h3 {
  font-size: 1.2rem;
 
}

.role {
  
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.stars {
  color: gold;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.review {
  font-size: 1rem;
  
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonial-card {
    padding: 1rem;
  }

  .testimonial-content h3 {
    font-size: 1rem;
  }

  .review {
    font-size: 0.9rem;
  }
  .section-title{
    font-size: 2rem;
  }
}
